exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-luxury-tall-wardrobes-jsx": () => import("./../../../src/pages/luxury-tall-wardrobes.jsx" /* webpackChunkName: "component---src-pages-luxury-tall-wardrobes-jsx" */),
  "component---src-pages-modular-wardrobe-jsx": () => import("./../../../src/pages/modular-wardrobe.jsx" /* webpackChunkName: "component---src-pages-modular-wardrobe-jsx" */),
  "component---src-pages-moduler-kitchen-js": () => import("./../../../src/pages/moduler-kitchen.js" /* webpackChunkName: "component---src-pages-moduler-kitchen-js" */),
  "component---src-pages-moduler-tv-unit-js": () => import("./../../../src/pages/moduler-tv-unit.js" /* webpackChunkName: "component---src-pages-moduler-tv-unit-js" */),
  "component---src-pages-package-jsx": () => import("./../../../src/pages/package.jsx" /* webpackChunkName: "component---src-pages-package-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

